const AppSettings = {
    appTitle: 'Nomadis',
    mapsalgerie: '34.442277, 3.492232',
	   // devise : 'Francs',
        //millimes: 'centimes',
       //decimal : 2,
    devise : 'Dinars',
    millimes: 'millimes',
    decimal : 3,

    show_poids : true,
    zoomalgerie : 7,
    keymaps :'AIzaSyBD2HxGeV6JrleH2XhcNb5lyN1B3WkRAls&v=3&libraries=geometry',
	 // apiUrl:'https://api.eurolait.nomadis.online/api/v1/',
        //apiUrl: 'https://api.bizerte.natilait.online/api/v1/',
	//apiUrl: 'https://api.nomadis.tantoushgroup.online/api/v1/',
	//apiUrl: 'https://api.benghazi.tantoushgroup.online/api/v1/',
	//apiUrl: 'https://api.mosrata.tantoushgroup.online/api/v1/',
	// apiUrl: 'https://api.krimiya.tantoushgroup.online/api/v1/',
	//  apiUrl:'https://api.demo.clediss.online/api/v1/',
        // apiUrl:'https://api.3p.nomadis.online/api/v1/',
        //apiUrl:'https://api.sancella.clediss.ovh/api/v1/',
        //apiUrl: 'https://api.algerie.clediss.com/api/v1/',
        // apiUrl: 'https://api.distrisam.nomadis.online/api/v1/',
        // apiUrl: 'https://api.distrisam3.nomadis.online/api/v1/',
        //apiUrl: 'https://api.nomadismostaganem.nomadis.online/api/v1/',
       // apiUrl: 'https://api.mostaganem.clediss.online/api/v1/',
	// apiUrl: 'https://api.nomadistiaret.nomadis.online/api/v1/',
        // apiUrl: 'https://api.rimofood.clediss.online/api/v1/',
        // apiUrl: 'https://api.triplecare.clediss.online/api/v1/',
        //apiUrl: 'https://api.joys.nomadis.online/api/v1/',
        //apiUrl: 'https://api.mondial.nomadis.online/api/v1/',
        //apiUrl: 'https://api.clediss.nomadis.online/api/v1/',
        // apiUrl:'https://api.3p.nomadis.online/api/v1/',
        //SANCELLA
	// apiUrl: 'https://api.nomadis.sancella.online/api/v1/',
        // apiUrl: 'https://api.fubus.sancella.online/api/v1/',
        // apiUrl: 'https://api.canal-food.sancella.online/api/v1/',
        // apiUrl: 'https://api.sidibouzid.sancella.online/api/v1/',
        // apiUrl: 'https://api.medenine.sancella.online/api/v1/',
        //NATILAIT
       // apiUrl: 'https://api.sousse.natilait.online/api/v1/',
        //apiUrl: 'https://api.plsousse.natilait.online/api/v1/',
	//apiUrl: 'https://api.mahdia.natilait.online/api/v1/',
        //apiUrl: 'https://api.sfax.natilait.online/api/v1/',
        //apiUrl: 'https://api.sfax2.natilait.online/api/v1/',
       //apiUrl: 'https://api.plsfax.natilait.online/api/v1/',
	//apiUrl: 'https://api.marsa.natilait.online/api/v1/',
        //apiUrl: 'https://api.bnikhalled.natilait.online/api/v1/',
        //apiUrl: 'https://api.gasrine.natilait.online/api/v1/',
       // apiUrl: 'https://api.bousalem.natilait.online/api/v1/',
	// apiUrl: 'https://api.seliana.natilait.online/api/v1/',
       //apiUrl: 'https://api.elkef.natilait.online/api/v1/',
        //apiUrl: 'https://api.gabes.natilait.online/api/v1/',
        //apiUrl: 'https://api.utique.natilait.online/api/v1/',
        //apiUrl: 'https://api.test.natilait.online/api/v1/',
        //apiUrl: 'https://api.kairouan.natilait.online/api/v1/',
	 apiUrl: 'https://api.jerba.natilait.online/api/v1/',
        //apiUrl: 'https://api.bizerte.natilait.online/api/v1/',
        //apiUrl: 'https://api.medenine.natilait.online/api/v1/',
        // apiUrl: 'https://api.metlaoui.natilait.online/api/v1/',
        // apiUrl: 'https://api.slimen.natilait.online/api/v1/',
        // apiUrl: 'https://api.sidibouzid.natilait.online/api/v1/',
        // apiUrl: 'https://api.mateur.natilait.online/api/v1/',
       	//apiUrl: 'https://api.test.natilait.online/api/v1/',
	//apiUrl: 'https://api.morneg.natilait.online/api/v1/',
	//apiUrl: 'https://api.capbon.natilait.online/api/v1/',
	 herewego : {
                app_id : 'VxpPmyKjSh1FcbqCq7d7',
                app_code : 'sjYx8madd1LAp_IP0Qo1GQ'
        },
        //using mapbox accesstocken
        leaflet : {
                access_token : 'pk.eyJ1IjoiY2xlZGlzc25vbWFkaXMiLCJhIjoiY2p0b2w1MHJqMDU2czQ1cG5tY3Bu$'
        },
       lang : 'fr_FR',
       // lang : 'ar_AR',
        //SERVER NODEJS AND SOCKET
        USING_SOCKET_AND_REDIS : false,
        BROADCAST_PORT : 3444,
        ADRESS_SERVER : 'http://localhost'
};
export default AppSettings;

